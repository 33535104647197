import { Outlet, NavLink, Link } from "react-router-dom";

import styles from "./Layout.module.css";
import { AuthButton } from "../../components/LogOutButton";
import { useIsAuthenticated } from "@azure/msal-react";

const Layout = () => {
    const isAuthenticated = useIsAuthenticated();
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <img src="/Fearnley_Seurities_CMYK_svg_hvit.svg" height={30} alt={"Fearnley Securities Logo"} />
                    </Link>
                    {isAuthenticated && (
                        <nav>
                            <ul className={styles.headerNavList}>
                                <li>
                                    <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                        Chat
                                    </NavLink>
                                </li>
                                <li className={styles.headerNavLeftMargin}>
                                    <NavLink to="/qa" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                        Ask a question
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    )}
                    <AuthButton />
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
