import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { PrimaryButton } from "@fluentui/react";

export function AuthButton() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    if (!isAuthenticated) {
        return <PrimaryButton onClick={() => instance.loginPopup()}>Sign in</PrimaryButton>;
    }
    return <PrimaryButton onClick={() => instance.logout()}>Sign out</PrimaryButton>;
}
