import React from "react";
import { PrimaryButton } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import styles from "./Login.module.css";
export function Login() {
    const { instance } = useMsal();

    return (
        <div className={styles.container}>
            {/* Welcome text for unauthenticated users */}
            <h1 className={styles.heading}>Welcome to the Fearnley Securities AI Chat</h1>
            <p className={styles.subTitle}>Please sign-in to continue.</p>
            <PrimaryButton className={styles.button} onClick={() => instance.loginPopup()}>
                Sign in
            </PrimaryButton>
        </div>
    );
}
