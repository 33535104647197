import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser"; // Import InteractionType
import { MsalProvider, useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import { Login } from "./pages/login/Login";

initializeIcons();

const msalConfig = {
    auth: {
        clientId: "907059b3-6870-4c3d-84d1-ab363d8d51dc",
        authority: "https://login.microsoftonline.com/c288c771-c0e2-4e8c-b1ef-7e1ca1e5d216"
    }
};

const msalInstance = new PublicClientApplication(msalConfig);

export default function App() {
    const isAuthenticated = useIsAuthenticated();

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={isAuthenticated ? <Chat /> : <Login />} />
                    <Route path="qa" element={isAuthenticated ? <OneShot /> : <Login />} />
                    <Route path="*" element={isAuthenticated ? <NoPage /> : <Login />} />
                </Route>
            </Routes>
        </HashRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <MsalProvider instance={msalInstance}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </MsalProvider>
);
